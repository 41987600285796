main.nosotros{
    .container_nosotros{
        @include media-breakpoint-up(md){
            max-width: 100%;
            
        }
    }
    .background_nosotros{
        background-image: url('/images/nosotros/background.jpg');
        background-repeat: no-repeat;
        display: none;
        @include media-breakpoint-up(md){
            display: block;
        }
    }
    .container_info{
        padding:40px 20px;
        background:color("blue","dark-1");
        color:white;
        @include media-breakpoint-up(md){
            padding: 5% 3%;
        }
        h1,h2,h3{
            font-size:5.5vw;
            font-weight: 300;
            font-family: $ofertare-font-avenir;
            @include media-breakpoint-up(md) { 
                font-size: 1.5rem; 
            }
            span{
                font-weight: 900;
            }
        }
        .section{
            margin-bottom:30px;
            .info_text{
                font-weight: 400;
                font-size: 4vw;
                text-align: justify;
                @include media-breakpoint-up(md) { 
                    font-size: 0.9rem; 
                }
            }
        }
    }
}
main.aviso-de-privacidad h1{
    margin: 69px 0 33px;
    text-align: center;
}